import React from 'react'
import { Card as AlliumCard } from '@telus-uds/components-web'

import { mapDataAttrsToDataSet } from '../../../utils/dataAttributes'
import { ResponsiveProps } from '../../../utils/types/components'
import Image, { type BasicImageProps } from '../Image/Image'

export interface FileProps {
  fileName: string
  url: string
}

type FullBleedImagePositions = 'none' | 'top' | 'bottom' | 'left' | 'right'
type FullBleedContentAlignmentOptions = 'start' | 'center' | 'end'

export type CardProps = {
  children: React.ReactNode
  background?: 'alternative' | 'subtle' | 'grid' | 'feature' | 'plain'
  padding?: 'narrow' | 'intermediate' | 'compact' | 'custom'
  borderRadius?: 'default' | 'none' | 'small' | 'large'
  fullBleedImagePosition?: ResponsiveProps<FullBleedImagePositions>
  fullBleedContentAlignment?: ResponsiveProps<FullBleedContentAlignmentOptions>
  fullBleedImage?: BasicImageProps
  decorative?: boolean
  imgAltText?: string
  limitHeight?: boolean
}

const Card = ({
  children,
  background,
  padding,
  borderRadius = 'default',
  fullBleedImagePosition = undefined,
  fullBleedContentAlignment = undefined,
  fullBleedImage,
  decorative = false,
  imgAltText = '',
  limitHeight = true,
  ...rest
}: CardProps) => {
  const CardWrapper = limitHeight ? 'div' : React.Fragment

  return (
    <CardWrapper data-testid="card-wrapper-testid">
      <AlliumCard
        testID="card-testid"
        fullBleedContent={
          fullBleedImage?.src
            ? {
                position: fullBleedImagePosition,
                align: fullBleedContentAlignment,
                content: (
                  <Image
                    src={fullBleedImage.src}
                    width={fullBleedImage.width}
                    height={fullBleedImage.height}
                    // if imgAltText empty use the text from the image description
                    alt={imgAltText || fullBleedImage.alt}
                    decorative={decorative}
                    // TODO: implement sizes
                    // sizes={{ sm: 100, md: 50, xl: 35 }}
                  />
                ),
              }
            : undefined
        }
        variant={{
          background,
          padding,
          borderRadius,
        }}
        dataSet={mapDataAttrsToDataSet(rest)}
      >
        {children}
      </AlliumCard>
    </CardWrapper>
  )
}

export default Card
